/* Outer-ring */
[id^="gauge-page-"] circle:nth-child(1) {
  /* stroke-width: 5; */
  stroke: #161D26;
  fill: rgba(2, 158, 254, 0.295);
}

/* Main background */
[id^="gauge-page-"] circle:nth-child(2) {
  fill: #161D26;
  stroke: #161D26;
  stroke-width: 1;
}

/* Circle of the pointer */
[id^="gauge-page-"] circle:nth-child(3) {
  fill: black;
  stroke: white;
  stroke-width: 2px;
}

/* Main text of the gauge */
[id^="gauge-page-"] text {
  fill: #1A76D2;
  font-family: 'Orbitron';
  font-weight: 100;
  font-size: 14px;
}

/* Current value text */
[id^="gauge-page-"] text:nth-child(1) {
  fill: #1A76D2;
  font-size: 33px;
  font-weight: 700;
  font-family: 'Orbitron';
}

/* Ticks */
[id^="gauge-page-"] path {
  stroke: white;

}

/* Circle
  [id^="gauge-page-"] path:nth-child(2) {
    fill: #FC6C05;
    stroke: white;
    stroke-width: 1px;
    fill-opacity: 1;
  } */

/* Warning area */
[id^="gauge-page-"] path:nth-child(3) {
  fill: lightgreen;
}

/* Danger area */
[id^="gauge-page-"] path:nth-child(4) {
  fill: darkgreen;
}


/* Gauge in Youtube video sentiment */
[id^="gauge-sentiment"] text {
  font-family: 'Orbitron';
  font-weight: 100;
  font-size: 12px;
}

[id^="gauge-sentiment"] text:nth-child(1) {
  font-size: 20px;
  font-weight: 400;
}