
.global-ranking-column {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #33CC3344 !important;
}

.global-platform-column {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #338fcc44 !important;
}

.global-weighted-column {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cc333344 !important;
}

.ag-row {
    border: 1;
    --ag-row-border-style: solid;
    --ag-row-border-width: 1px;
}

.ag-panel-title-bar {
    background-color: #161D26 !important;
}

.ag-column-drop {
    background-color: #161D26 !important;
    color: #539FE5 !important;
}

.ag-root-wrapper {

    border-radius: 10px;
    height: auto;
}

/* .ag-root .ag-cell {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
} */

.ag-header-group-cell {
    font-weight: 600;
    font-size: 14px;
}

.ag-header-cell {
    font-weight: 700;
    font-size: 14px;
}

.ag-theme-quartz-dark {
    --ag-header-background-color: #0f161f !important;
    --ag-background-color: #161D26 !important;
    --ag-odd-row-background-color: #161D26 !important;
    --ag-row-hover-color: #5598fb5f;
    --ag-secondary-foreground-color: rgb(255, 255, 255);
    --ag-card-shadow: 0 5px 14px rgb(0, 0, 0);
    --ag-popup-shadow: 0 10px 14px rgb(0, 0, 0);
    --ag-range-selection-border-color: #3e4555;
    --ag-popup-background-color: #001929 !important;
    --ag-header-height: 40px;
    --ag-header-foreground-color: white;
    --ag-control-panel-background-color: #161D26;
    /* --ag-selected-row-background-color: rgb(85, 107, 47); */
}

.ag-menu {
    background-color: #161D26;
}

.card {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
}

.border-radius-10 {
    border-radius: 10px;
}

.ag-center-cols-clipper {
    min-height: 50px !important;
}

.ag-header-cell.text-left .ag-header-cell-label {
    justify-content: left;
}

.ag-theme-quartz-dark.compact {
    --ag-grid-size: 5px;
    --ag-list-item-height: 24px;
    --ag-font-size: 13px;
    --ag-header-height: 24px;
    --ag-borders: 'none';
    --ag-header-background-color: #161D26;
    --ag-background-color: #161D26;
    --ag-row-hover-color: none;
}

.ag-theme-quartz-dark.compact .ag-header-cell::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: #494b5b;
}

.ag-theme-quartz.compact {
    --ag-grid-size: 5px;
    --ag-list-item-height: 24px;
    --ag-font-size: 13px;
    --ag-header-height: 24px;
    --ag-borders: 'none';
    --ag-row-hover-color: none;
}

.ag-theme-quartz.compact .ag-header-cell::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: #494b5b;
}

.ag-grid-compact.compact {
    --ag-grid-size: 5px;
    --ag-list-item-height: 24px;
    --ag-font-size: 13px;
    --ag-header-height: 24px;
    --ag-borders: 'none';
    --ag-row-hover-color: none;
}

.ag-grid-compact.compact .ag-header-cell::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: #494b5b;
}