.countryselector-fixed-width {
  width: 150px;
}

.filter-white-icon {
  filter: brightness(0) invert(1);
}

[data-style-id="border-radius-0"] {
  border-radius: 0px !important;
}

[data-style-id="border-radius-0-parent"] div {
  border-radius: 0px !important;
}

[data-custom-input-width] {
  width: 50px;
}
