@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html,
body {
  margin: 0;
  background-color: #161D26;
}

.controller_container {
  margin-right: 5px;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;

  left: 6rem;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 8rem;
  top: 0;
}

.rdrDefinedRangesWrapper,
.rdrDateDisplayWrapper {
  background: inherit !important;
}
.rdrStaticRange {
  background: inherit !important;
  border-color: #696969 !important;
}


.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.line-clamp-6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.line-clamp-8 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
}

.circle-wrap {
  width: 50px;
  height: 50px;
  background: #000716;
  border-radius: 50%;
}

.circle-wrap .circle .mask, .circle-wrap .circle .fill {
  width: 50px;
  height: 50px;
  position: absolute;
  border-radius: 50%;
}

.circle-wrap .circle .mask {
  clip: rect(0px, 50px, 50px, 25px);
}

.circle-wrap .circle .mask .fill {
  clip: rect(0px, 25px, 50px, 0px);
  background-color: green;
}

.timeline-card {
  padding: 15px !important;
  border-radius: 0.75rem !important;
  border: 2px solid #94a3b8;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif !important;
  min-width: 250px;
}
.timeline-card-text {
  padding: 0 !important;
  margin: 0 !important;
}
.timeline-card header {
  padding: 0 !important;
  margin: 0 !important;
}
.timeline-title {
  padding: 0.25rem 0.5rem !important;
  border-radius: 0.5rem !important;
}

.comment-234-wrapper .ql-editor {
  font-size: 16px !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.ql-tooltip.ql-hidden {
  height: 0 !important;
  overflow: hidden;
}

.placeholder-quill-wrapper .ql-editor .ql-blank::before {
  color: #4B5563 !important;
}

.rank-container {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
  align-items: center;
  height: 100%;
}

.slider-no-labels [role='listitem'] {
  display: none;
}

.bg-background {
  background-color: white;
}

.dark .bg-background {
  background-color: #161D26;
}