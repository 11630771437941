.hide-scrollbar {
    /* For webkit browsers */
    -webkit-overflow-scrolling: touch;
}
  
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}
  
.hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
  