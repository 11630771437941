

.ag-theme-quartz-dark {
    --ag-header-background-color: #000716;
    --ag-background-color: #161D26 !important;
    --ag-odd-row-background-color: #161D26 !important;
    --ag-row-hover-color: #5598fb5f;
    --ag-secondary-foreground-color: rgb(255, 255, 255);
    --ag-card-shadow: 0 5px 14px rgb(0, 0, 0);
    --ag-popup-shadow: 0 10px 14px rgb(0, 0, 0);
    --ag-range-selection-border-color: #3e4555;
    --ag-popup-background-color: #001929 !important;
    --ag-header-height: 40px;
    --ag-header-foreground-color: white;
    --ag-control-panel-background-color: #161D26;
    /* --ag-selected-row-background-color: rgb(85, 107, 47); */
}

/* Style overriding for specific ag grid cases*/
div[data-id="ag-grid-react"].ag-theme-quartz .ag-header-group-cell {
    color: #000
}

div[data-id="ag-grid-react"].ag-theme-quartz-dark.ag-header-group-cell {
    color: #E5E4E2
}

div[data-id="ag-grid-react"].ag-theme-quartz.ag-header-cell {
    color: #000
}

div[data-id="ag-grid-react"].ag-theme-quartz-dark.ag-header-cell {
    color: #9fb5ce
}

div[data-id="ag-grid-react"].ag-theme-quartz.ag-row {
    --ag-row-border-color: #909021
}

div[data-id="ag-grid-react"].ag-theme-quartz-dark.ag-row {
    --ag-row-border-color: rgba(255, 255, 255, 0.096)
}

div[data-id="ag-grid-react"].ag-theme-quartz.ag-row-level-1 {
    color: #fff
}

div[data-id="ag-grid-react"].ag-theme-quartz-dark.ag-row-level-1 {
    color: #0c1622
}

div[data-id="ag-grid-react"].ag-theme-quartz.ag-row-level-2 {
    color: #fff
}

div[data-id="ag-grid-react"].ag-theme-quartz-dark.ag-row-level-2 {
    color: #000
}